import React, { useRef, useEffect, useState, useLayoutEffect } from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import FuseAnimate from "@fuse/core/FuseAnimate";
import { useDispatch, useSelector } from "react-redux";
import Button from "@material-ui/core/Button";
import TextareaAutosize from "@material-ui/core/TextareaAutosize";
import { updateUserNotes } from "../../../auth/store/userSlice";
import moment from "moment-timezone";
import { showMessage } from "../../../store/fuse/messageSlice";
import { removeCatchup } from "./store/chatSlice";
import ConfirmationDialog from "../../ConfirmationDialog";
import isEmpty from "lodash/isEmpty";
import history from "../../../../@history/@history";
import clsx from "clsx";
import {getActivityFeed} from "./store/activitiesSlice";

const bgImg = "assets/images/site/side_panel_purple_261px.png";

const useStyles = makeStyles((theme) => ({
  root: {},
  sidebarcontainer: {
    width: "261px",
    // height: "356px",
    minWidth: "261px",
    minHeight: "356px",
    backgroundImage: `url(${bgImg})`,
    padding: "16px",
    color: "#000000",
    fontWeight: "bold",
    marginBottom: "26px",
  },
  purpletext: {
    color: "#521B5F !important",
    fontWeight: "bold",
  },
  sidebarhr: {
    color: "#521B5F !important",
    borderColor: "#521B5F !important",
    marginTop: "6px",
    marginBottom: "6px",
  },
  sidebarbody: {
    paddingTop: "6px",
    fontSize: "12px",
    fontWeight: "regular",
  },
  notesField: {
    width: "100%",
    maxHeight: "100px",
    padding: "6px",
  },
}));

const isBig = (userRole) => userRole[0].toLowerCase() === "big";

function ActivityFeedSideBar({
  launchCatchup,
  requestCatchup,
  editCatchupRequest,
  confirmCatchupRequest,
  isCatchupAvailable,
  isCatchupNow,
  areCatchupRequestsEnabled,
  adminApprovedCatchups,
  isCatchupRequestAvailable,
  isCatchupRequestConfirmed,
  setIsCatchupAvailable,
}) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const user = useSelector(({ auth }) => auth.user);
  const match = user.data.match;
  const catchup = useSelector(({ feedApp }) => feedApp.chat.catchup);
  const catchupRequest = useSelector(
    ({ feedApp }) => feedApp.catchupRequests.catchupRequest
  );
  const notesRef = useRef(null);
  const mountedRef = useRef(true);
  const isMatchOnline = useSelector(({ auth }) => auth.socket.matchOnline);

  const [isCancelDialogOpen, setIsCancelDialogOpen] = useState(false);
  const [catchupRequestTitle, setCatchupRequestTitle] = useState("");
  const [showControls, setShowControls] = useState(false);
  const [showConfirm, setShowConfirm] = useState(true);
  const [isRequestor, setIsRequestor] = useState(false);

  const handleSaveNotes = (e) => {
    e.stopPropagation();
    dispatch(
      updateUserNotes({
        id: user.data.id,
        notes: notesRef.current.value,
      })
    ).then((result) => {
      let message;
      if (result && result.payload && result.payload.error) {
        message = result.payload.error;
      } else {
        message = "Notes updated successfully";
      }
      dispatch(showMessage({ message: message }));
    });
  };

  const deleteCatchup = (id, match_id) => {
    dispatch(removeCatchup({ catchup_id: id, match_id: match_id }));
  };

  useEffect(() => {
    if (isEmpty(catchup)) {
      setIsCatchupAvailable(false);
    }
  }, [catchup]);

  // change the title and dates etc when the request changes
  useEffect(() => {
    if (!isEmpty(catchupRequest)) {
      const dateStr = `${moment
        .utc(catchupRequest.requestedStart_at)
        .format("dddd MMM Do")} at ${moment
        .utc(catchupRequest.requestedStart_at)
        .format("hh:mm A")}`;

      let statusText = "requested";
      let approvalText = "";
      if (catchupRequest?.bConfirmed) {
        statusText = "confirmed";
      }

      setCatchupRequestTitle(
        `Catchup ${statusText} for ${dateStr}. ${approvalText}`
      );

      if (catchupRequest.requestor_id === user.data.id) {
        setIsRequestor(true);
      } else {
        setIsRequestor(false);
      }

      if (catchupRequest?.bConfirmed) {
        setShowControls(false);
        setShowConfirm(false);
      } else {
        setShowControls(true);
        setShowConfirm(true);
      }
    } else {
      setShowControls(false);
      setShowConfirm(false);
    }
  }, [catchupRequest, setCatchupRequestTitle]);

  useEffect(() => {}, [
    isCatchupRequestAvailable,
    isCatchupAvailable,
    catchupRequest,
  ]);

  useEffect(() => {
    notesRef.current.value = user.data.notes;
  }, [user]);

  const handleCancelDialogClose = (confirm, cancel) => {
    setIsCancelDialogOpen(false);
    if (confirm) {
      deleteCatchup(catchup.id, match.id);
    }
  };

  const gotoCatchups = () => {
    history.push({
      pathname: "/apps/catchupCalendar",
    });
  };

  const renderCatchupRequestStatus = () => {
    if (isCatchupRequestAvailable && !isEmpty(catchupRequest)) {
      if (!catchupRequest?.bConfirmed && showControls) {
        return (
          <>
            <p className={clsx(classes.sidebarbody, "mb-8")}>
              {catchupRequestTitle}
            </p>
            {showConfirm && !isRequestor && (
              <Button
                variant="contained"
                color="secondary"
                onClick={(e) => {
                  e.stopPropagation();
                  confirmCatchupRequest();
                }}
                className="mr-8"
              >
                Confirm
              </Button>
            )}
            {/*&nbsp;&nbsp;*/}
            {showControls && catchupRequest && !catchupRequest?.bConfirmed && (
              <Button
                variant="contained"
                color="secondary"
                onClick={(e) => {
                  e.stopPropagation();
                  editCatchupRequest();
                }}
              >
                Change
              </Button>
            )}
          </>
        );
      }
      return (
        <>
          {adminApprovedCatchups ? (
            <p className={classes.sidebarbody}>Awaiting admin approval...</p>
          ) : (
            <p className={classes.sidebarbody}>
              {`Catchup confirmed for${" "}
            ${moment(catchup.scheduledStart_at).format("dddd MMM Do")} at${" "}
            ${moment(catchup.scheduledStart_at).format("hh:mm A")}`}
            </p>
          )}
          {isCatchupNow && (
            <Button
              variant="contained"
              color="secondary"
              onClick={launchCatchup}
              className="mr-8"
            >
              Join Catchup
            </Button>
          )}

          <Button
            variant="contained"
            color="secondary"
            onClick={(e) => {
              e.stopPropagation();
              setIsCancelDialogOpen(true);
            }}
          >
            Cancel
          </Button>
        </>
      );
    } else {
      return (
        <>
          <p className={classes.sidebarbody}>No catchups requested</p>
          {areCatchupRequestsEnabled && !showControls && (
            <Button
              variant="contained"
              color="secondary"
              onClick={(e) => {
                e.stopPropagation();
                requestCatchup();
              }}
            >
              Request a Catchup
            </Button>
          )}
        </>
      );
    }
  };

  return (
    <FuseAnimate animation="transition.fadeIn" ref={mountedRef}>
      <>
        <div className={classes.sidebarcontainer}>
          {match && (
            <h3>
              {isBig(user.role) ? match.little.firstname : match.big.firstname}{" "}
              is{" "}
              <span className={classes.purpletext}>
                {isMatchOnline ? "Online" : "Offline"}
              </span>
            </h3>
          )}

          <hr className={classes.sidebarhr} />
          <h4>Next Catchup:</h4>

          {/* Next Catchup */}
          {isCatchupAvailable && (
            <>
              <p className={classes.sidebarbody}>
                {`Catchup confirmed for${" "}
                ${moment(catchup.scheduledStart_at).format(
                  "dddd MMM Do"
                )} at${" "}
                ${moment(catchup.scheduledStart_at).format("hh:mm A")}`}
              </p>
              {isCatchupNow && (
                <Button
                  variant="contained"
                  color="secondary"
                  onClick={launchCatchup}
                  className="mr-8"
                >
                  Join Catchup
                </Button>
              )}
              <Button
                variant="contained"
                color="secondary"
                onClick={(e) => {
                  e.stopPropagation();
                  setIsCancelDialogOpen(true);
                }}
              >
                Cancel
              </Button>
            </>
          )}

          {/* Catchup Requests  */}
          {renderCatchupRequestStatus()}

          {/* Request a Catchup */}
          {}

          <br />
          <br />
          <hr className={classes.sidebarhr} />
          <h3>Notes</h3>
          <TextareaAutosize
            ref={notesRef}
            className={classes.notesField}
            aria-label="Notes"
            placeholder="Add some notes..."
            rows={4}
          />
          <Button variant="outlined" size="small" onClick={handleSaveNotes}>
            Save
          </Button>

          <Button
            variant="contained"
            color="primary"
            className="my-8"
            onClick={gotoCatchups}
          >
            View Full Calendar
          </Button>
        </div>
        <ConfirmationDialog
          onClose={handleCancelDialogClose}
          open={isCancelDialogOpen}
          title="Cancel Catchup"
          message="Are you sure you want to cancel catchup?"
        />
      </>
    </FuseAnimate>
  );
}

ActivityFeedSideBar.propTypes = {
  catchup: PropTypes.object,
  launchCatchup: PropTypes.func.isRequired,
  requestCatchup: PropTypes.func.isRequired,
  editCatchupRequest: PropTypes.func.isRequired,
  confirmCatchupRequest: PropTypes.func.isRequired,
  isCatchupAvailable: PropTypes.bool.isRequired,
  isCatchupRequestAvailable: PropTypes.bool.isRequired,
  isCatchupNow: PropTypes.bool.isRequired,
  adminApprovedCatchups: PropTypes.bool.isRequired,
  areCatchupRequestsEnabled: PropTypes.bool.isRequired,
  isCatchupRequestConfirmed: PropTypes.bool.isRequired,
};

export default ActivityFeedSideBar;
